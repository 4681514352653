// import React from 'react';
// import { Link } from 'react-scroll';
// import logo from '../assets/logo2.svg';

// const Header = () => {
//   return (
//     <header>
//       <div className="logo-container">
//         {/* <a href="home"> 
//           <img src={logo} alt="Klozka Chemicals Logo" className="logo" />
//         </a> */}
//         <Link to="home" smooth={true} duration={500}>
//             <img src={logo} alt="Klozka Chemicals Logo" className="logo" />
//         </Link>
//         {/* <span className="company-name">Klozka Chemicals</span> */}
//       </div>
//       <nav>
//         <ul className="nav-links">
//           <li>
//             <Link to="about" smooth={true} duration={500}>
//               About Us
//             </Link>
//           </li>
//           <li>
//             <Link to="values" smooth={true} duration={500}>
//               Our Values
//             </Link>
//           </li>
//           <li>
//             <Link to="products" smooth={true} duration={500}>
//               Our Products
//             </Link>
//           </li>
//           <li>
//             <Link to="contact" smooth={true} duration={500}>
//               Contact Us
//             </Link>
//           </li>
//         </ul>
//       </nav>
//     </header>
//   );
// };

// export default Header;
import React, { useState } from 'react';
import { Link } from 'react-scroll';
import logo from '../assets/logo2.svg';

const Header = () => {
  const [isMobile, setIsMobile] = useState(false); // State to toggle mobile menu

  const toggleMenu = () => {
    setIsMobile(!isMobile); // Toggle the menu state
  };

  return (
    <header>
      <div className="logo-container">
        <Link to="home" smooth={true} duration={500} aria-label="Go to Home">
          <img src={logo} alt="Klozka Chemicals Logo" className="logo" />
        </Link>
      </div>

      <nav className="nav">
        {/* Apply the "show" class to ul based on isMobile state */}
        <ul className={isMobile ? "nav-links-mobile show" : "nav-links-mobile"}>
          <li>
            <Link to="about" smooth={true} duration={500} onClick={() => setIsMobile(false)}>
              About Us
            </Link>
          </li>
          <li>
            <Link to="values" smooth={true} duration={500} onClick={() => setIsMobile(false)}>
              Our Values
            </Link>
          </li>
          <li>
            <Link to="products" smooth={true} duration={500} onClick={() => setIsMobile(false)}>
              Our Products
            </Link>
          </li>
          <li>
            <Link to="contact" smooth={true} duration={500} onClick={() => setIsMobile(false)}>
              Contact Us
            </Link>
          </li>
        </ul>

        <button className="hamburger" onClick={toggleMenu}>
          {/* Hamburger icon with lines */}
          <span className="hamburger-line"></span>
          <span className="hamburger-line"></span>
          <span className="hamburger-line"></span>
        </button>
      </nav>
    </header>
  );
};

export default Header;

