import React, { useState, useEffect } from 'react';
import newWave from '../assets/NewWaveWithImg.png'
import productImage from '../assets/Product.jpg';
import contactBg from '../assets/contact-bg.jpg';
import newWaveSS from '../assets/NewWaveSmallScreen.png'
import productImageSS from '../assets/ProductSmallScreen.jpg'


const Products = () => {
    const [isMobile, setIsMobile] = useState(false);

  // Function to check if the window is mobile size
    const checkIsMobile = () => {
        setIsMobile(window.innerWidth <= 768);
    };

  // Add event listener for resize
    useEffect(() => {
        checkIsMobile(); // Check on mount

        window.addEventListener('resize', checkIsMobile);

        // Cleanup event listener on unmount
        return () => {
            window.removeEventListener('resize', checkIsMobile);
        };
  }, []);
  return (
    <><section id="products" className="products">
          <div className="product-details">
              <img src={isMobile ? newWaveSS : newWave} className='responsive-img' alt='new wave of speciality plasticizers' />
          </div>
      </section><section id="products" className="products" style={{ backgroundImage: `url(${contactBg})` }}>
              <div className="product-details" style={{textAlign:"center"}}>
                  <div style={{ position: 'relative', top: '-10px' }}>
                  <h2>Specially Curated</h2>
                  </div>
                  <h1>PVC Plasticizers</h1>
                  <h4><i>PVC Processing, Reimagined!!</i></h4>
                  <h3>Forte Amalgam:</h3>
                  <div style={{ width: "70%", margin: "0 auto", textAlign: "center" }}>
                  <p>Molecular geometry plays a pivotal role in determining the unique properties of our matrix material. By carefully manipulating molecular arrangements, we can enhance the lattice structure and optimize product performance.
                      Through meticulous analysis of spatial geometries, we are able to refine our products, improving their quality, versatility, and overall value.
                  </p>
                  </div>
                  <h3>Additives</h3>
                  <div style={{ width: "70%", margin: "0 auto", textAlign: "center" }}>
                  <p>By minimizing migration, fortifying UV resistance, and preventing leach-out, we can significantly enhance the longevity and performance of our products. These improvements not only contribute to a more sustainable and reliable product but also ensure compliance with environmental regulations and safety standards.</p>
                  </div>
                  <img src={isMobile ? productImageSS : productImage} className='responsive-img' alt="PVC Plasticizers" />
              </div>
          </section></>
  );
};

export default Products;

