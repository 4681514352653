import React from 'react';
import { Link } from 'react-scroll';
import bannerImage from '../assets/banner.jpg';

const Home = () => {
  return (
    <section id="home" className="home" style={{ backgroundImage: `url(${bannerImage})` }}>
      <div className="home-content">
        <div style={{ position: 'relative', top: '-100px' }}>
        <h1>"We Design your Plasticizers"</h1>
        </div>
        <Link to="products" smooth={true} duration={500}>
          <button className="explore-button">Explore our Products</button>
        </Link>
      </div>
    </section>
  );
};

export default Home;
