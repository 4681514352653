import React from 'react';
import contactBg from '../assets/contact-bg.jpg';

const Contact = () => {
  return (
    <section id="contact" className="contact" style={{ backgroundImage: `url(${contactBg})` }}>
      <h2>Contact Us</h2>
      <p>Email: office@klozkachemicals.com</p>
      <p>Phone: +91-8830813797</p>
      <p>Address: New Akashganga CHSL, Yamuna Nagar, Andheri...</p>
    </section>
  );
};

export default Contact;
