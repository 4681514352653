import React, { useState, useEffect } from 'react';
import valuesImage from '../assets/ourValues.jpg'
import valuesImageSS from '../assets/ourValuesSmallScreen.jpg'

const Values = () => {
  const [isMobile, setIsMobile] = useState(false);

  // Function to check if the window is mobile size
  const checkIsMobile = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  // Add event listener for resize
  useEffect(() => {
    checkIsMobile(); // Check on mount

    window.addEventListener('resize', checkIsMobile);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);
  return (
    <section id="values" className="values">
      <img src={isMobile ? valuesImageSS : valuesImage} alt='our values'/>
      <div style={{width:"70%"}}>
      <h3>At KLOZKA CHEMICAL PVT LTD, we’re committed to building a strong foundation rooted in maintaining a continuous flow of innovation and while remaining receptive to new ideas from the outset. This unwavering commitment allows us to achieve an exceptional balance of strength and elegance.</h3>
      </div>
    </section>
  );
};

export default Values;
