import React from 'react';
import Header from './components/Header';
import Home from './components/Home';
import About from './components/About';
import Values from './components/Values';
import Products from './components/Products';
import Contact from './components/Contact';
import './styles/styles.css';
// import Products2 from './components/Products2';

function App() {
  return (
    <div className="App">
      <Header />
      <Home />
      <About />
      <Values />
      <Products />
      {/* <Products2/> */}
      <Contact />
    </div>
  );
}

export default App;
