import React, { useState, useEffect } from 'react';
import missionImage from "../assets/ourMission.jpg"
import missionImageSS from "../assets/ourMissionSmallScreen.jpg"

const About = () => {
  const [isMobile, setIsMobile] = useState(false);

  // Function to check if the window is mobile size
  const checkIsMobile = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  // Add event listener for resize
  useEffect(() => {
    checkIsMobile(); // Check on mount

    window.addEventListener('resize', checkIsMobile);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);
  return (
    <section id="about" className="about">
      <div>
        <h1>About Us</h1>
      </div>
      <img src={isMobile ? missionImageSS : missionImage} alt='Our Mission'/>
      <div style={{width:"70%"}}>
      <h3>At KLOZKA CHEMICAL PVT LTD, we take pride in being recognized as the chemical specialists. Our mission is to work hard for this goal and aim to better ourselves at every turn through meticulous and disciplined research. We are committed to upholding our core value of;</h3>
      <h3>“The PERFECT SOLUTION? Is yet to be found”</h3>
      <h3>which guides every decision we make and every project we undertake.</h3>
      </div>
    </section>
  );
};

export default About;
